import { useCheckbox } from '@react-aria/checkbox';
import { useFocusRing } from '@react-aria/focus';
import {
  AriaTableProps,
  useTable,
  useTableCell,
  useTableColumnHeader,
  useTableHeaderRow,
  useTableRow,
  useTableRowGroup,
  useTableSelectAllCheckbox,
  useTableSelectionCheckbox,
} from '@react-aria/table';
import { mergeProps } from '@react-aria/utils';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { PartialNode } from '@react-stately/collections';
import {
  Cell as AriaCell,
  Column as AriaColumn,
  Row as AriaRow,
  TableBody as AriaTableBody,
  TableHeader as AriaTableHeader,
  CollectionBuilderContext,
  TableState,
  TableStateProps,
  useTableState,
} from '@react-stately/table';
import { useToggleState } from '@react-stately/toggle';
import { GridNode } from '@react-types/grid';
import { Node } from '@react-types/shared';
import {
  CellProps as AriaCellProps,
  ColumnProps as AriaColumnProps,
  RowProps as AriaRowProps,
  TableHeaderProps as AriaTableHeaderProps,
} from '@react-types/table';
import React, { useRef } from 'react';


import { mapResponsiveValue, sprinkles } from '../../styles/sprinkles.css';
import { assignResponsiveProperty, DynamicResponsiveProps } from '../../styles/utils';
import childrenAreLiteral from '../../webutils/childrenAreLiteral';
import Box, { BoxOwnProps } from '../Box';
import Checkbox from '../Checkbox';
import Grid from '../Grid';
import { IconSortable, IconTriangle } from '../Icons';
import Text from '../Text';

import * as styles from './Table.css';

/** Work in progress table abstraction. Meant for mostly data. Things that are
 * more loosely grid-like and interaction need a different abstraction.
 * Currently struggles with:
 *  - responsiveness
 *  - certain interactive elements (input's need special handling to enter spaces, for example)
 *  - rows that act like links (the old table mixin has a partial solution, it needs to be ported here)
 *  - probably more
 * This is still my preferred method for building tables going forward, but
 * certain designs may require additional features being added or fixed.
 */
interface TableProps<T extends {}> extends AriaTableProps<T>, TableStateProps<T> {
  showSelectAll?: boolean;
  /** Added for support for existing designs. I'm not sure this is a reasonable
   * thing to use though. Table's need headers. Maybe just render another thing? I
   * don't know yet though.
   */
  headerVisibility?: BoxOwnProps['visibility'];
  /** Sets what the Table should render when there is no content to display. */
  renderEmptyState?: () => JSX.Element,
}

const Table = <T extends {}>(props: TableProps<T>) => {
  const tableState = useTableState(props);

  const tableRef = useRef<HTMLTableElement>(null);

  const { gridProps } = useTable(props, tableState, tableRef);
  const { collection } = tableState;

  return (
    <Box
      as='table'
      borderTop='greyLine'
      ref={tableRef}
      {...mergeProps(gridProps, { className: styles.table })}
    >
      <TableRowGroup type='thead'>
        {collection.headerRows.map(headerRow => (
          <TableHeaderRow
            key={headerRow.key}
            item={headerRow}
            state={tableState}
            headerVisibility={props.headerVisibility}
          >
            {Array.from(headerRow.childNodes).map((column, index, arr) => {
              // eslint-disable-next-line no-nested-ternary
              const position = index === 0 ? 'first' : (index === arr.length - 1 ? 'last' : undefined);
              if (column.props.isSelectionCell) {
                return (
                  <TableSelectAllCell
                    key={column.key}
                    column={column}
                    state={tableState}
                    position={position}
                    hidden={!props.showSelectAll}
                  />
                );
              }
              return (
                <TableColumnHeader
                  key={column.key}
                  column={column}
                  state={tableState}
                  position={position}
                />
              );
            })}
          </TableHeaderRow>
        ))}
      </TableRowGroup>
      <TableRowGroup
        type='tbody'
        // Quick and incomplete support for the Table.Body `loadingState` prop
        // to indicate a table is being sorted/filtered/extended with an opacity veil
        style={{ opacity: collection.body.props?.loadingState === 'loading' ? 0.75 : undefined, transition: 'opacity 0.15s' }}
      >
        {(() => {
          const childNodes = Array.from(collection.body.childNodes);
          if (childNodes.length === 0) {
            const emptyState = props.renderEmptyState?.() ?? null;
            if (emptyState === null) return null;
            return <CenteredWrapper state={tableState}>{emptyState}</CenteredWrapper>;
          }
          return (
            Array.from(childNodes).map(row => (
              <TableRow key={row.key} item={row} state={tableState}>
                {Array.from(row.childNodes).map((cell: GridNode<T>, index, arr) => {
                  // eslint-disable-next-line no-nested-ternary
                  const position = index === 0 ? 'first' : (index === arr.length - 1 ? 'last' : undefined);
                  const unselectable = (row.props as RowProps<T>).unselectable ?? false;
                  if (cell.props.isSelectionCell) {
                    return (
                      <TableCheckboxCell key={cell.key} cell={cell} state={tableState} position={position} hidden={unselectable} />
                    );
                  }
                  return (
                    <TableCell key={cell.key} cell={cell} state={tableState} position={position} />
                  );
                })}
              </TableRow>
            ))
          );
        })()}
      </TableRowGroup>
    </Box>
  );
};

interface TableRowGroupProps {
  type: 'tbody' | 'thead';
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const TableRowGroup = ({ type: Element, style, children }: TableRowGroupProps) => {
  const { rowGroupProps } = useTableRowGroup();
  return (
    <Element {...rowGroupProps} style={style}>
      {children}
    </Element>
  );
};

interface TableHeaderRowProps<T extends {}> {
  item: Node<T>;
  state: TableState<T>;
  children?: React.ReactNode;
  headerVisibility?: BoxOwnProps['visibility'];
}

const TableHeaderRow = <T extends {}>({ item, state, children, headerVisibility }: TableHeaderRowProps<T>) => {
  const rowRef = useRef<HTMLTableRowElement>(null);
  const { rowProps } = useTableHeaderRow({ node: item }, state, rowRef);

  return (
    <tr {...rowProps} ref={rowRef} className={sprinkles.atoms({ visibility: headerVisibility })}>
      {children}
    </tr>
  );
};

interface TableColumnHeaderProps<T extends {}> {
  column: Omit<GridNode<T>, 'props'> & { props?: ColumnProps<T> };
  state: TableState<T>;
  position: 'first' | 'last' | undefined;
}

const TableColumnHeader = <T extends {}>(props: TableColumnHeaderProps<T>) => {
  const { column, state, position } = props;
  const headerRef = useRef<HTMLTableHeaderCellElement>(null);
  const { columnHeaderProps } = useTableColumnHeader(
    { node: column },
    state,
    headerRef,
  );
  const { isFocusVisible, focusProps } = useFocusRing();
  const textAlign = column.props?.textAlign
    ?? ((column.colspan ?? 1) > 1 ? 'center' : 'left');

  const { maxWidth, minWidth, width, whiteSpace } = column.props ?? {};
  const minWidthProps = assignResponsiveProperty(styles.minWidthTheme, minWidth);
  const maxWidthProps = assignResponsiveProperty(styles.maxWidthTheme, maxWidth);
  const widthProps = assignResponsiveProperty(styles.widthTheme, width);

  return (
    <Box
      as='th'
      paddingY='space-1'
      paddingLeft={position === 'first' ? 'none' : 'space-1'}
      paddingRight={position === 'last' ? 'none' : 'space-1'}
      whiteSpace={whiteSpace}
      {...mergeProps(columnHeaderProps, focusProps)}
      colSpan={column.colspan}
      style={{
        cursor: 'default',
        ...minWidthProps.style,
        ...maxWidthProps.style,
        ...widthProps.style,
      }}
      className={[
        isFocusVisible && styles.outline,
        minWidthProps.className,
        maxWidthProps.className,
        widthProps.className,
      ]}
      ref={headerRef}
    >
      <Grid
        gridAutoFlow='column'
        alignItems='center'
        justifyContent={mapResponsiveValue(textAlign, (align) => {
          if (align === 'center') return 'center';
          if (align === 'right') return 'end';
          return 'start';
        })}
        columnGap={(column.props?.allowsSorting) ? 'space-0.25' : undefined}
      >
        <Text fontWeight='bold' fontSize='small' textAlign={textAlign}>
          {column.rendered}
        </Text>
        {(() => {
          if (!column.props?.allowsSorting) return null;
          const direction = state.sortDescriptor.direction === 'ascending' ? 'up' : 'down';
          const icon = (state.sortDescriptor.direction === undefined || state.sortDescriptor.column !== column.key)
            ? (
              <IconSortable position='relative' foreground='greyPlaceholder' />
            ) : (
              <IconTriangle
                position='relative'
                foreground='black'
                direction={direction}
              />
            );
          return (
            <Box position='relative' minHeight='100%'>
              {icon}
            </Box>
          );
        })()}
      </Grid>
    </Box>
  );
};

interface TableRowProps<T extends {}> {
  item: Node<T>;
  children?: React.ReactNode;
  state: TableState<T>;
}

const TableRow = <T extends {}>({ item, children, state }: TableRowProps<T>) => {
  const rowRef = useRef<HTMLTableRowElement>(null);
  const { rowProps, hasAction, allowsSelection } = useTableRow({
    node: item,
  }, state, rowRef);
  const { isFocusVisible, focusProps } = useFocusRing();

  return (
    <Box
      as='tr'
      className={isFocusVisible && styles.outline}
      {...mergeProps(rowProps, focusProps, { style: { outline: isFocusVisible ? undefined : 'none' } })}
      cursor={(hasAction || allowsSelection) ? 'pointer' : undefined}
      ref={rowRef}
    >
      {children}
    </Box>
  );
};

interface TableCellProps<T extends {}> {
  cell: GridNode<T>;
  state: TableState<T>;
  position: 'first' | 'last' | undefined;
  border?: boolean;
}

const TableCell = <T extends {}>(props: TableCellProps<T>) => {
  const { cell, state, position } = props;
  const cellProps = cell.props as CellProps;
  const hasBorder = cellProps.border ?? props.border ?? true;
  const cellRef = useRef<HTMLTableCellElement>(null);
  const { gridCellProps } = useTableCell({ node: cell }, state, cellRef);
  const { isFocusVisible, focusProps } = useFocusRing();

  const columnProps = cell.column?.props as ColumnProps<T> | undefined;
  const textAlign = cellProps.textAlign
    || (columnProps)?.textAlign;

  const { maxWidth, minWidth, width } = columnProps ?? {};
  const minWidthProps = assignResponsiveProperty(styles.minWidthTheme, minWidth);
  const maxWidthProps = assignResponsiveProperty(styles.maxWidthTheme, maxWidth);
  const widthProps = assignResponsiveProperty(styles.widthTheme, width);

  const child = cell.props?.colSpan !== 0 ? cell.rendered : null;

  return (
    <Box
      as='td'
      paddingY='space-1'
      paddingLeft={position === 'first' ? 'none' : 'space-1'}
      paddingRight={position === 'last' ? 'none' : 'space-1'}
      borderBottom={hasBorder ? 'greyLine' : undefined}
      textAlign={textAlign}
      colSpan={cellProps.colSpan}
      {...mergeProps(gridCellProps, focusProps)}
      style={{
        verticalAlign: 'middle',
        ...minWidthProps.style,
        ...maxWidthProps.style,
        ...widthProps.style,
      }}
      className={[
        isFocusVisible && styles.outline,
        minWidthProps.className,
        maxWidthProps.className,
        widthProps.className,
      ]}
      ref={cellRef}
    >
      {childrenAreLiteral(cell.rendered) ? (
        <Text>
          {child}
        </Text>
      ) : (
        <>
          {child}
        </>
      )}
    </Box>
  );
};

interface TableCheckboxCellProps<T extends {}> {
  cell: GridNode<T>;
  state: TableState<T>;
  position: 'first' | 'last' | undefined;
  border?: boolean;
  hidden: boolean | undefined;
}

const TableCheckboxCell = <T extends {}>(props: TableCheckboxCellProps<T>) => {
  const { cell, state, border = true, position, hidden } = props;
  const cellRef = useRef<HTMLTableCellElement>(null);
  const { gridCellProps } = useTableCell({ node: cell }, state, cellRef);
  const { checkboxProps } = useTableSelectionCheckbox(
    { key: cell.parentKey ?? cell.key },
    state,
  );

  const inputRef = useRef<HTMLInputElement>(null);
  const { inputProps } = useCheckbox(
    checkboxProps,
    useToggleState(checkboxProps),
    inputRef,
  );

  return (
    <Box
      as='td'
      borderBottom={(border && !hidden) ? 'greyLine' : undefined}
      paddingLeft={position === 'first' ? 'none' : 'space-1'}
      paddingRight={position === 'last' ? 'none' : 'space-1'}
      {...mergeProps(gridCellProps, { style: { verticalAlign: 'middle' } })}
      ref={cellRef}
    >
      {hidden ? (<VisuallyHidden>This row is not selectable</VisuallyHidden>) : (<Checkbox {...inputProps} ref={inputRef} />)}
    </Box>
  );
};

interface TableSelectAllCellProps<T extends {}> {
  column: Node<T>;
  state: TableState<T>;
  position: 'first' | 'last' | undefined;
  hidden: boolean | undefined;
}

const TableSelectAllCell = <T extends {}>(props: TableSelectAllCellProps<T>) => {
  const { column, state, position } = props;
  const headerRef = useRef<HTMLTableHeaderCellElement>(null);
  const isSingleSelectionMode = state.selectionManager.selectionMode === 'single';
  const hidden = isSingleSelectionMode || props.hidden;
  const { columnHeaderProps } = useTableColumnHeader(
    { node: column },
    state,
    headerRef,
  );

  const { checkboxProps } = useTableSelectAllCheckbox(state);
  const inputRef = useRef<HTMLInputElement>(null);
  const { inputProps } = useCheckbox(
    checkboxProps,
    useToggleState(checkboxProps),
    inputRef,
  );

  return (
    <Box
      as='th'
      textAlign='left'
      paddingY='space-1'
      paddingLeft={position === 'first' ? 'none' : 'space-1'}
      paddingRight={position === 'last' ? 'none' : 'space-1'}
      fontWeight='bold'
      fontSize='small'
      style={{
        verticalAlign: 'middle',
      }}
      {...columnHeaderProps}
      ref={headerRef}
    >
      {hidden
        ? (<VisuallyHidden>{inputProps['aria-label']}</VisuallyHidden>)
        : (<Checkbox {...inputProps} ref={inputRef} />)}
    </Box>
  );
};

type ColumnElement<T> = React.ReactElement<ColumnProps<T>>;
type ColumnRenderer<T> = (item: T) => ColumnElement<T>;
interface TableHeaderProps<T> extends Omit<AriaTableHeaderProps<T>, 'children'> {
  children: ColumnElement<T> | (ColumnElement<T> | null)[] | ColumnRenderer<T>
}

// react-aria's method for building data out of children is using these dummy
// components with a generator that inspects the components children
const TableHeader = <T extends {}>(_props: TableHeaderProps<T>): React.ReactElement | null => {
  return null;
};

// We wrap the provided generator to allow and filter out `null` values, so you
// can dynamically render one or two things without having to use an `items` and
// a render function
TableHeader.getCollectionNode = function* getCollectionNode<T>(
  props: TableHeaderProps<T>,
  context: CollectionBuilderContext<T>,
): Generator<PartialNode<T>> {
  const children = (() => {
    if (typeof props.children === 'function') {
      return props.children;
    }
    const children: ColumnElement<T>[] = [];
    React.Children.forEach(props.children, (elem) => {
      if (elem === null) return;
      children.push(elem);
    });
    return children;
  })();
  const tableHeaderGetCollectionNode = (AriaTableHeader as unknown as {
    getCollectionNode<T>(props: TableHeaderProps<T>, context: CollectionBuilderContext<T>): Generator<PartialNode<T>>
  }).getCollectionNode;
  const generator = tableHeaderGetCollectionNode({ ...props, children }, context);
  let done: boolean;
  do {
    const next = generator.next();
    done = next.done || false;
    yield next.value;
  } while (!done);
};

type CellElement = React.ReactElement<CellProps>;
type CellRenderer = (columnKey: React.Key) => CellElement;

interface RowProps<T> extends Omit<AriaRowProps<T>, 'children'> {
  children: CellElement | (CellElement | null)[] | CellRenderer,
  unselectable?: boolean;
}

const Row = <T extends unknown>(_props: RowProps<T>) => {
  return null;
};

Row.getCollectionNode = function* getCollectionNode<T>(props: RowProps<T>, context: CollectionBuilderContext<T>): Generator<PartialNode<T>> {
  const children = (() => {
    if (typeof props.children === 'function') {
      return props.children;
    }
    const children: CellElement[] = [];
    React.Children.forEach(props.children, (elem) => {
      if (elem === null) return;
      children.push(elem);
    });
    return children;
  })();
  const rowGetCollectionNode = (AriaRow as unknown as {
    getCollectionNode<T>(props: RowProps<T>, context: CollectionBuilderContext<T>): Generator<PartialNode<T>>
  }).getCollectionNode;
  const generator = rowGetCollectionNode({ ...props, children }, context);
  let done: boolean;
  do {
    const next = generator.next();
    done = next.done || false;
    yield next.value;
  } while (!done);
};

interface ColumnProps<T> extends Omit<AriaColumnProps<T>, 'minWidth' | 'maxWidth' | 'width'> {
  textAlign?: BoxOwnProps['textAlign'];
  colSpan?: number;
  minWidth?: DynamicResponsiveProps<typeof styles.minWidthTheme>['minWidth'];
  maxWidth?: DynamicResponsiveProps<typeof styles.maxWidthTheme>['maxWidth'];
  width?: DynamicResponsiveProps<typeof styles.widthTheme>['width'];
  whiteSpace?: BoxOwnProps['whiteSpace'];
}
// We just need the prop typing, all logic is internal to `Table`
const Column = AriaColumn as <T extends {}>(props: ColumnProps<T>) => React.ReactElement;

interface CellProps extends AriaCellProps {
  textAlign?: BoxOwnProps['textAlign'];
  colSpan?: number;
  border?: boolean;
}
// We just need the prop typing, all logic is internal to `Table`
const Cell = AriaCell as (props: CellProps) => React.ReactElement;

interface CenteredWrapperProps<T extends {}> {
  state: TableState<T>;
  children?: React.ReactNode;
}

const CenteredWrapper = <T extends {}>(props: CenteredWrapperProps<T>) => {
  const { state, children } = props;
  const { isFocusVisible, focusProps } = useFocusRing();
  return (
    <Box
      as='tr'
      role='row'
      aria-rowindex={state.collection.headerRows.length + state.collection.size + 1}
      className={isFocusVisible && styles.outline}
      borderBottom='greyLine'
      {...focusProps}
    >
      <td colSpan={state.collection.columns.length}>
        {children}
      </td>
    </Box>
  );
};

export default Object.assign(Table, {
  Header: TableHeader,
  Row,
  Column,
  Cell,
  Body: AriaTableBody,
});

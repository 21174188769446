var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useCheckbox } from '@react-aria/checkbox';
import { useFocusRing } from '@react-aria/focus';
import { useTable, useTableCell, useTableColumnHeader, useTableHeaderRow, useTableRow, useTableRowGroup, useTableSelectAllCheckbox, useTableSelectionCheckbox, } from '@react-aria/table';
import { mergeProps } from '@react-aria/utils';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { Cell as AriaCell, Column as AriaColumn, Row as AriaRow, TableBody as AriaTableBody, TableHeader as AriaTableHeader, useTableState, } from '@react-stately/table';
import { useToggleState } from '@react-stately/toggle';
import React, { useRef } from 'react';
import { mapResponsiveValue, sprinkles } from '../../styles/sprinkles.css';
import { assignResponsiveProperty } from '../../styles/utils';
import childrenAreLiteral from '../../webutils/childrenAreLiteral';
import Box from '../Box';
import Checkbox from '../Checkbox';
import Grid from '../Grid';
import { IconSortable, IconTriangle } from '../Icons';
import Text from '../Text';
import * as styles from './Table.css';
var Table = function (props) {
    var _a;
    var tableState = useTableState(props);
    var tableRef = useRef(null);
    var gridProps = useTable(props, tableState, tableRef).gridProps;
    var collection = tableState.collection;
    return (<Box as='table' borderTop='greyLine' ref={tableRef} {...mergeProps(gridProps, { className: styles.table })}>
      <TableRowGroup type='thead'>
        {collection.headerRows.map(function (headerRow) { return (<TableHeaderRow key={headerRow.key} item={headerRow} state={tableState} headerVisibility={props.headerVisibility}>
            {Array.from(headerRow.childNodes).map(function (column, index, arr) {
                // eslint-disable-next-line no-nested-ternary
                var position = index === 0 ? 'first' : (index === arr.length - 1 ? 'last' : undefined);
                if (column.props.isSelectionCell) {
                    return (<TableSelectAllCell key={column.key} column={column} state={tableState} position={position} hidden={!props.showSelectAll}/>);
                }
                return (<TableColumnHeader key={column.key} column={column} state={tableState} position={position}/>);
            })}
          </TableHeaderRow>); })}
      </TableRowGroup>
      <TableRowGroup type='tbody' 
    // Quick and incomplete support for the Table.Body `loadingState` prop
    // to indicate a table is being sorted/filtered/extended with an opacity veil
    style={{ opacity: ((_a = collection.body.props) === null || _a === void 0 ? void 0 : _a.loadingState) === 'loading' ? 0.75 : undefined, transition: 'opacity 0.15s' }}>
        {(function () {
            var _a, _b;
            var childNodes = Array.from(collection.body.childNodes);
            if (childNodes.length === 0) {
                var emptyState = (_b = (_a = props.renderEmptyState) === null || _a === void 0 ? void 0 : _a.call(props)) !== null && _b !== void 0 ? _b : null;
                if (emptyState === null)
                    return null;
                return <CenteredWrapper state={tableState}>{emptyState}</CenteredWrapper>;
            }
            return (Array.from(childNodes).map(function (row) { return (<TableRow key={row.key} item={row} state={tableState}>
                {Array.from(row.childNodes).map(function (cell, index, arr) {
                    var _a;
                    // eslint-disable-next-line no-nested-ternary
                    var position = index === 0 ? 'first' : (index === arr.length - 1 ? 'last' : undefined);
                    var unselectable = (_a = row.props.unselectable) !== null && _a !== void 0 ? _a : false;
                    if (cell.props.isSelectionCell) {
                        return (<TableCheckboxCell key={cell.key} cell={cell} state={tableState} position={position} hidden={unselectable}/>);
                    }
                    return (<TableCell key={cell.key} cell={cell} state={tableState} position={position}/>);
                })}
              </TableRow>); }));
        })()}
      </TableRowGroup>
    </Box>);
};
var TableRowGroup = function (_a) {
    var Element = _a.type, style = _a.style, children = _a.children;
    var rowGroupProps = useTableRowGroup().rowGroupProps;
    return (<Element {...rowGroupProps} style={style}>
      {children}
    </Element>);
};
var TableHeaderRow = function (_a) {
    var item = _a.item, state = _a.state, children = _a.children, headerVisibility = _a.headerVisibility;
    var rowRef = useRef(null);
    var rowProps = useTableHeaderRow({ node: item }, state, rowRef).rowProps;
    return (<tr {...rowProps} ref={rowRef} className={sprinkles.atoms({ visibility: headerVisibility })}>
      {children}
    </tr>);
};
var TableColumnHeader = function (props) {
    var _a, _b, _c, _d, _e;
    var column = props.column, state = props.state, position = props.position;
    var headerRef = useRef(null);
    var columnHeaderProps = useTableColumnHeader({ node: column }, state, headerRef).columnHeaderProps;
    var _f = useFocusRing(), isFocusVisible = _f.isFocusVisible, focusProps = _f.focusProps;
    var textAlign = (_b = (_a = column.props) === null || _a === void 0 ? void 0 : _a.textAlign) !== null && _b !== void 0 ? _b : (((_c = column.colspan) !== null && _c !== void 0 ? _c : 1) > 1 ? 'center' : 'left');
    var _g = (_d = column.props) !== null && _d !== void 0 ? _d : {}, maxWidth = _g.maxWidth, minWidth = _g.minWidth, width = _g.width, whiteSpace = _g.whiteSpace;
    var minWidthProps = assignResponsiveProperty(styles.minWidthTheme, minWidth);
    var maxWidthProps = assignResponsiveProperty(styles.maxWidthTheme, maxWidth);
    var widthProps = assignResponsiveProperty(styles.widthTheme, width);
    return (<Box as='th' paddingY='space-1' paddingLeft={position === 'first' ? 'none' : 'space-1'} paddingRight={position === 'last' ? 'none' : 'space-1'} whiteSpace={whiteSpace} {...mergeProps(columnHeaderProps, focusProps)} colSpan={column.colspan} style={__assign(__assign(__assign({ cursor: 'default' }, minWidthProps.style), maxWidthProps.style), widthProps.style)} className={[
            isFocusVisible && styles.outline,
            minWidthProps.className,
            maxWidthProps.className,
            widthProps.className,
        ]} ref={headerRef}>
      <Grid gridAutoFlow='column' alignItems='center' justifyContent={mapResponsiveValue(textAlign, function (align) {
            if (align === 'center')
                return 'center';
            if (align === 'right')
                return 'end';
            return 'start';
        })} columnGap={((_e = column.props) === null || _e === void 0 ? void 0 : _e.allowsSorting) ? 'space-0.25' : undefined}>
        <Text fontWeight='bold' fontSize='small' textAlign={textAlign}>
          {column.rendered}
        </Text>
        {(function () {
            var _a;
            if (!((_a = column.props) === null || _a === void 0 ? void 0 : _a.allowsSorting))
                return null;
            var direction = state.sortDescriptor.direction === 'ascending' ? 'up' : 'down';
            var icon = (state.sortDescriptor.direction === undefined || state.sortDescriptor.column !== column.key)
                ? (<IconSortable position='relative' foreground='greyPlaceholder'/>) : (<IconTriangle position='relative' foreground='black' direction={direction}/>);
            return (<Box position='relative' minHeight='100%'>
              {icon}
            </Box>);
        })()}
      </Grid>
    </Box>);
};
var TableRow = function (_a) {
    var item = _a.item, children = _a.children, state = _a.state;
    var rowRef = useRef(null);
    var _b = useTableRow({
        node: item,
    }, state, rowRef), rowProps = _b.rowProps, hasAction = _b.hasAction, allowsSelection = _b.allowsSelection;
    var _c = useFocusRing(), isFocusVisible = _c.isFocusVisible, focusProps = _c.focusProps;
    return (<Box as='tr' className={isFocusVisible && styles.outline} {...mergeProps(rowProps, focusProps, { style: { outline: isFocusVisible ? undefined : 'none' } })} cursor={(hasAction || allowsSelection) ? 'pointer' : undefined} ref={rowRef}>
      {children}
    </Box>);
};
var TableCell = function (props) {
    var _a, _b, _c, _d, _e;
    var cell = props.cell, state = props.state, position = props.position;
    var cellProps = cell.props;
    var hasBorder = (_b = (_a = cellProps.border) !== null && _a !== void 0 ? _a : props.border) !== null && _b !== void 0 ? _b : true;
    var cellRef = useRef(null);
    var gridCellProps = useTableCell({ node: cell }, state, cellRef).gridCellProps;
    var _f = useFocusRing(), isFocusVisible = _f.isFocusVisible, focusProps = _f.focusProps;
    var columnProps = (_c = cell.column) === null || _c === void 0 ? void 0 : _c.props;
    var textAlign = cellProps.textAlign
        || ((_d = (columnProps)) === null || _d === void 0 ? void 0 : _d.textAlign);
    var _g = columnProps !== null && columnProps !== void 0 ? columnProps : {}, maxWidth = _g.maxWidth, minWidth = _g.minWidth, width = _g.width;
    var minWidthProps = assignResponsiveProperty(styles.minWidthTheme, minWidth);
    var maxWidthProps = assignResponsiveProperty(styles.maxWidthTheme, maxWidth);
    var widthProps = assignResponsiveProperty(styles.widthTheme, width);
    var child = ((_e = cell.props) === null || _e === void 0 ? void 0 : _e.colSpan) !== 0 ? cell.rendered : null;
    return (<Box as='td' paddingY='space-1' paddingLeft={position === 'first' ? 'none' : 'space-1'} paddingRight={position === 'last' ? 'none' : 'space-1'} borderBottom={hasBorder ? 'greyLine' : undefined} textAlign={textAlign} colSpan={cellProps.colSpan} {...mergeProps(gridCellProps, focusProps)} style={__assign(__assign(__assign({ verticalAlign: 'middle' }, minWidthProps.style), maxWidthProps.style), widthProps.style)} className={[
            isFocusVisible && styles.outline,
            minWidthProps.className,
            maxWidthProps.className,
            widthProps.className,
        ]} ref={cellRef}>
      {childrenAreLiteral(cell.rendered) ? (<Text>
          {child}
        </Text>) : (<>
          {child}
        </>)}
    </Box>);
};
var TableCheckboxCell = function (props) {
    var _a;
    var cell = props.cell, state = props.state, _b = props.border, border = _b === void 0 ? true : _b, position = props.position, hidden = props.hidden;
    var cellRef = useRef(null);
    var gridCellProps = useTableCell({ node: cell }, state, cellRef).gridCellProps;
    var checkboxProps = useTableSelectionCheckbox({ key: (_a = cell.parentKey) !== null && _a !== void 0 ? _a : cell.key }, state).checkboxProps;
    var inputRef = useRef(null);
    var inputProps = useCheckbox(checkboxProps, useToggleState(checkboxProps), inputRef).inputProps;
    return (<Box as='td' borderBottom={(border && !hidden) ? 'greyLine' : undefined} paddingLeft={position === 'first' ? 'none' : 'space-1'} paddingRight={position === 'last' ? 'none' : 'space-1'} {...mergeProps(gridCellProps, { style: { verticalAlign: 'middle' } })} ref={cellRef}>
      {hidden ? (<VisuallyHidden>This row is not selectable</VisuallyHidden>) : (<Checkbox {...inputProps} ref={inputRef}/>)}
    </Box>);
};
var TableSelectAllCell = function (props) {
    var column = props.column, state = props.state, position = props.position;
    var headerRef = useRef(null);
    var isSingleSelectionMode = state.selectionManager.selectionMode === 'single';
    var hidden = isSingleSelectionMode || props.hidden;
    var columnHeaderProps = useTableColumnHeader({ node: column }, state, headerRef).columnHeaderProps;
    var checkboxProps = useTableSelectAllCheckbox(state).checkboxProps;
    var inputRef = useRef(null);
    var inputProps = useCheckbox(checkboxProps, useToggleState(checkboxProps), inputRef).inputProps;
    return (<Box as='th' textAlign='left' paddingY='space-1' paddingLeft={position === 'first' ? 'none' : 'space-1'} paddingRight={position === 'last' ? 'none' : 'space-1'} fontWeight='bold' fontSize='small' style={{
            verticalAlign: 'middle',
        }} {...columnHeaderProps} ref={headerRef}>
      {hidden
            ? (<VisuallyHidden>{inputProps['aria-label']}</VisuallyHidden>)
            : (<Checkbox {...inputProps} ref={inputRef}/>)}
    </Box>);
};
// react-aria's method for building data out of children is using these dummy
// components with a generator that inspects the components children
var TableHeader = function (_props) {
    return null;
};
// We wrap the provided generator to allow and filter out `null` values, so you
// can dynamically render one or two things without having to use an `items` and
// a render function
TableHeader.getCollectionNode = function getCollectionNode(props, context) {
    var children, tableHeaderGetCollectionNode, generator, done, next;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                children = (function () {
                    if (typeof props.children === 'function') {
                        return props.children;
                    }
                    var children = [];
                    React.Children.forEach(props.children, function (elem) {
                        if (elem === null)
                            return;
                        children.push(elem);
                    });
                    return children;
                })();
                tableHeaderGetCollectionNode = AriaTableHeader.getCollectionNode;
                generator = tableHeaderGetCollectionNode(__assign(__assign({}, props), { children: children }), context);
                _a.label = 1;
            case 1:
                next = generator.next();
                done = next.done || false;
                return [4 /*yield*/, next.value];
            case 2:
                _a.sent();
                _a.label = 3;
            case 3:
                if (!done) return [3 /*break*/, 1];
                _a.label = 4;
            case 4: return [2 /*return*/];
        }
    });
};
var Row = function (_props) {
    return null;
};
Row.getCollectionNode = function getCollectionNode(props, context) {
    var children, rowGetCollectionNode, generator, done, next;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                children = (function () {
                    if (typeof props.children === 'function') {
                        return props.children;
                    }
                    var children = [];
                    React.Children.forEach(props.children, function (elem) {
                        if (elem === null)
                            return;
                        children.push(elem);
                    });
                    return children;
                })();
                rowGetCollectionNode = AriaRow.getCollectionNode;
                generator = rowGetCollectionNode(__assign(__assign({}, props), { children: children }), context);
                _a.label = 1;
            case 1:
                next = generator.next();
                done = next.done || false;
                return [4 /*yield*/, next.value];
            case 2:
                _a.sent();
                _a.label = 3;
            case 3:
                if (!done) return [3 /*break*/, 1];
                _a.label = 4;
            case 4: return [2 /*return*/];
        }
    });
};
// We just need the prop typing, all logic is internal to `Table`
var Column = AriaColumn;
// We just need the prop typing, all logic is internal to `Table`
var Cell = AriaCell;
var CenteredWrapper = function (props) {
    var state = props.state, children = props.children;
    var _a = useFocusRing(), isFocusVisible = _a.isFocusVisible, focusProps = _a.focusProps;
    return (<Box as='tr' role='row' aria-rowindex={state.collection.headerRows.length + state.collection.size + 1} className={isFocusVisible && styles.outline} borderBottom='greyLine' {...focusProps}>
      <td colSpan={state.collection.columns.length}>
        {children}
      </td>
    </Box>);
};
export default Object.assign(Table, {
    Header: TableHeader,
    Row: Row,
    Column: Column,
    Cell: Cell,
    Body: AriaTableBody,
});

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useChat } from '@ai-sdk/react';
import { isLeft } from 'fp-ts/lib/Either';
import * as t from 'io-ts';
import React, { useRef, useState } from 'react';
import urlJoin from 'url-join';
import { ensureMableError } from '@mablemarket/common-lib';
import { AiChatModelType } from '@mablemarket/core-api-client';
import Box from '../Box';
import Button from '../Button';
import Flex from '../Flex';
import Loading from '../Loading';
import Markdown from '../Markdown';
import Select from '../Select';
import Text from '../Text';
import TextLinkButton from '../TextLinkButton';
var exampleQueries = [
    'Break down my order volume by distributor',
    'Show me my top products ordered by volume',
    'When did I last order [product]?',
];
var AiChat = function (_a) {
    var accessToken = _a.accessToken, impersonationID = _a.impersonationID, chatPostBody = _a.chatPostBody, baseApiUrl = _a.baseApiUrl, showDeveloperFeatures = _a.showDeveloperFeatures, aiChatEndpoint = _a.aiChatEndpoint;
    var _b = useState('textGenerationSmart'), modelType = _b[0], setModelType = _b[1];
    var _c = useState('Claude'), modelProvider = _c[0], setModelProvider = _c[1];
    var _d = useState(undefined), lastError = _d[0], setLastError = _d[1];
    var _e = useState(undefined), tokenUsage = _e[0], setTokenUsage = _e[1];
    var _f = useState(false), showTokenUsage = _f[0], setShowTokenUsage = _f[1];
    var inputRef = useRef(null);
    var handleDelete = function (id) {
        setMessages(messages.filter(function (message) { return message.id !== id; }));
    };
    var additionalHeaders = __assign({}, (impersonationID ? { 'x-mable-impersonate-user-id': impersonationID } : {}));
    // useChat reference: https://sdk.vercel.ai/docs/reference/ai-sdk-ui/use-chat
    var _g = useChat({
        api: urlJoin(baseApiUrl, aiChatEndpoint),
        headers: __assign({ Authorization: "Bearer ".concat(accessToken) }, additionalHeaders),
        body: {
            includeDebugInfo: true,
        },
        onFinish: function (message, _a) {
            var usage = _a.usage, finishReason = _a.finishReason;
            setTokenUsage(usage);
        },
        onError: function (error) {
            setLastError(ensureMableError(error));
        },
        onResponse: function (response) {
            // ...
        },
    }), messages = _g.messages, setMessages = _g.setMessages, input = _g.input, handleInputChange = _g.handleInputChange, setInput = _g.setInput, handleSubmit = _g.handleSubmit, status = _g.status, stop = _g.stop, error = _g.error, reload = _g.reload;
    var modelTypeLabel = {
        reasoningFaster: 'Reasoning, Fast',
        reasoningSmarter: 'Reasoning, Smart',
        textGenerationMini: 'Standard, Fast',
        textGenerationSmart: 'Standard, Smart',
        textGenerationSmarter: 'Standard, Smarter (4.5 preview)',
    };
    var modelSelectionHeader = (<Box paddingX='space-1' paddingY='space-1' borderBottom='greyLine' background='greyFill'>
      <Flex>
        <Flex>
          <Select onChange={function (e) { return setModelProvider(e.target.value); }} value={modelProvider}>
            <option value='OpenAI'>OpenAI</option>
            <option value='Claude'>Claude</option>
          </Select>
        </Flex>
        <Flex>
          <Select onChange={function (e) { return setModelType(e.target.value); }} value={modelType}>
            {AiChatModelType.values.map(function (type) { return (<option key={type} value={type}>
                {modelTypeLabel[type]}
              </option>); })}
          </Select>
        </Flex>
        {tokenUsage && showDeveloperFeatures && (<Button onClick={function () { return setShowTokenUsage(!showTokenUsage); }} palette='greyDark' variant='ghost' size='small'>
            {showTokenUsage ? 'Hide Tokens' : 'Show Tokens'}
          </Button>)}
      </Flex>
    </Box>);
    return (<Flex flexDirection='column' background='white' borderRadius='medium' border='greyLine' style={{
            overflow: 'hidden',
        }}>

      {showDeveloperFeatures && modelSelectionHeader}

      {/* Messages container */}
      <Flex paddingTop='space-1' paddingX='space-2' gap='space-0.5' flexGrow={1} flexDirection='column' background='white' style={{
            overflowY: 'auto',
            width: '100%',
        }}>
        {!showDeveloperFeatures && messages.length === 0 && (<Flex flexDirection='column' alignItems='center' justifyContent='center' flexGrow={1} paddingY='space-4'>
            <Text fontSize='h3' fontWeight='bold' paddingBottom='space-1.5'>
              What can I help with?
            </Text>
            <Text fontSize='medium' foreground='greyDark' textAlign='center' paddingBottom='space-2'>
              You can ask me anything about your order data. For example:
            </Text>
            <Flex flexDirection='column' gap='space-1' style={{ width: '80%', maxWidth: '30rem' }}>
              {exampleQueries.map(function (example) { return (<Box key={example} background='greyFill' padding='space-1.5' borderRadius='medium' style={{ cursor: 'pointer' }} onClick={function () {
                    setInput(example);
                    setTimeout(function () { var _a; return (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus(); }, 0);
                }}>
                  <Text>{example}</Text>
                </Box>); })}
            </Flex>
          </Flex>)}

        {messages.length > 0 && (messages.map(function (message, idx) {
            return ([
                (idx > 0 && message.role === 'user') ? <Box paddingY='space-1'/> : null,
                <ChatMessage key={"".concat(message.id)} message={message} handleDelete={handleDelete}/>,
                (idx < messages.length - 1 && message.role === 'user') ? <Box paddingY='space-0.5'/> : null,
            ]);
        }))}

        {status === 'submitted' && (<Flex paddingY='space-2' justifyContent='center'>
            <Loading size={30}/>
          </Flex>)}

        {error && (<Box style={{
                padding: '0.75rem',
                backgroundColor: '#fef2f2',
                border: '0.0625rem solid #fee2e2',
                color: '#b91c1c',
            }} borderRadius='small'>
            <Box>
              <Text fontWeight='bold'>{lastError === null || lastError === void 0 ? void 0 : lastError.displayTitle}</Text>
              :
              {lastError === null || lastError === void 0 ? void 0 : lastError.displayMessage}
            </Box>
            <Button onClick={function () { return reload(); }} palette='greyDark' size='small'>
              Retry
            </Button>
          </Box>)}

        {/* Token usage stats */}
        {showTokenUsage && tokenUsage && (<Box>
            <TokenUsage usage={tokenUsage}/>
          </Box>)}

        <Box paddingY='space-1'/>
      </Flex>

      <AiChatInput modelType={modelType} modelProvider={modelProvider} input={input} handleInputChange={handleInputChange} handleSubmit={handleSubmit} status={status} error={error} stop={stop} chatPostBody={chatPostBody} inputRef={inputRef}/>
    </Flex>);
};
var ChatMessage = function (_a) {
    var message = _a.message, handleDelete = _a.handleDelete;
    var isUser = message.role === 'user';
    return (<Box background='white' style={{ width: '100%' }}>
      <Box style={{ maxWidth: '100%', overflowWrap: 'break-word' }}>
        <Flex alignItems='flex-start' justifyContent={isUser ? 'flex-end' : 'flex-start'} style={{ width: '100%' }}>
          {/* Message content */}
          <Box flexGrow={0} textAlign={isUser ? 'right' : 'left'} style={{
            width: isUser ? 'auto' : '100%',
            maxWidth: isUser ? '80%' : '100%',
        }}>
            {message.parts.map(function (part, index) {
            if (part.type === 'text') {
                return (<Box 
                // eslint-disable-next-line react/no-array-index-key
                key={index} fontSize='medium' background={isUser ? 'greyFill' : undefined} borderRadius={isUser ? 'medium' : undefined} padding={isUser ? 'space-1' : undefined}>
                    <Box>
                      <Markdown source={part.text}/>
                    </Box>
                  </Box>);
            }
            if (part.type === 'reasoning') {
                return (<Box 
                // eslint-disable-next-line react/no-array-index-key
                key={index} borderRadius='small' fontSize='small' style={{
                        backgroundColor: '#f0fdfa',
                        padding: '0.5rem 0.75rem',
                    }}>
                    <Box>
                      <Text fontWeight='bold'>Reasoning:</Text>
                      {' '}
                      {part.reasoning}
                    </Box>
                    <details>
                      <summary style={{ cursor: 'pointer' }}>Details</summary>
                      <pre style={{
                        fontSize: '0.75rem',
                        backgroundColor: '#f1f5f9',
                        padding: '0.5rem',
                        borderRadius: '0.25rem',
                        overflowX: 'hidden',
                    }}>
                        {JSON.stringify(part.details, null, 2)}
                      </pre>
                    </details>
                  </Box>);
            }
            if (part.type === 'source') {
                return (<Box 
                // eslint-disable-next-line react/no-array-index-key
                key={index} borderRadius='small' fontSize='small' style={{
                        backgroundColor: '#eff6ff',
                        padding: '0.5rem 0.75rem',
                    }}>
                    <Text fontWeight='bold'>Source:</Text>
                    <pre style={{
                        fontSize: '0.75rem',
                        backgroundColor: '#f1f5f9',
                        padding: '0.5rem',
                        borderRadius: '0.25rem',
                        overflowX: 'hidden',
                    }}>
                      {JSON.stringify(part.source, null, 2)}
                    </pre>
                  </Box>);
            }
            if (part.type === 'tool-invocation') {
                return ([
                    <Box key={"".concat(part.toolInvocation.toolCallId, "-spacer")} paddingY='space-0.25'/>,
                    <ToolPart key={"".concat(part.toolInvocation.toolCallId)} part={part}/>,
                ]);
            }
            if (part.type === 'step-start') {
                // eslint-disable-next-line react/no-array-index-key
                return index > 0 ? (<br key={index}/>) : null;
            }
            return (
            // eslint-disable-next-line react/no-array-index-key
            <Text key={index} foreground='greyDark' style={{ fontStyle: 'italic' }}>
                  Unhandled message part type:
                  {' '}
                  {part.type}
                </Text>);
        })}
          </Box>
        </Flex>
      </Box>
    </Box>);
};
var SqlQueryResultType = t.type({
    rows: t.array(t.record(t.string, t.union([t.string, t.number, t.boolean, t.null]))),
    rowCount: t.number,
});
var SqlQueryResult = function (_a) {
    var _b;
    var toolInvocation = _a.toolInvocation, collapsed = _a.collapsed;
    var result = (function () {
        if (toolInvocation.state !== 'result' || !toolInvocation.result) {
            return null;
        }
        var decodedResult = SqlQueryResultType.decode(toolInvocation.result);
        if (isLeft(decodedResult)) {
            return null;
        }
        return decodedResult.right;
    })();
    var resultsTable = (function () {
        if (!result || !result.rows || !result.rows[0]) {
            return null;
        }
        var tableStyles = {
            table: {
                width: '100%',
                tableLayout: 'fixed',
                borderCollapse: 'collapse',
                fontSize: '0.75rem',
            },
            headerRow: {
                backgroundColor: '#e2e8f0',
            },
            headerCell: {
                padding: '0.5rem',
                textAlign: 'left',
                borderBottom: '0.125rem solid #cbd5e1',
                fontWeight: 'bold',
            },
            row: function (isEven) { return ({
                backgroundColor: isEven ? '#f8fafc' : '#ffffff',
            }); },
            cell: {
                padding: '0.5rem',
                borderBottom: '0.0625rem solid #e2e8f0',
                wordBreak: 'break-word',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
            nullValue: {
                color: '#94a3b8',
            },
        };
        return (<table style={tableStyles.table}>
        <thead>
          <tr style={tableStyles.headerRow}>
            {Object.keys(result.rows[0]).map(function (column, index) { return (
            // eslint-disable-next-line react/no-array-index-key
            <th key={index} style={tableStyles.headerCell}>
                {column}
              </th>); })}
          </tr>
        </thead>
        <tbody>
          {result.rows.map(function (row, rowIndex) { return (
            // eslint-disable-next-line react/no-array-index-key
            <tr key={rowIndex} style={tableStyles.row(rowIndex % 2 === 0)}>
              {Object.values(row).map(function (cell, cellIndex) { return (
                // eslint-disable-next-line react/no-array-index-key
                <td key={cellIndex} style={tableStyles.cell}>
                  {(function () {
                        if (cell === null)
                            return <em style={tableStyles.nullValue}>NULL</em>;
                        if (typeof cell === 'object')
                            return JSON.stringify(cell);
                        return String(cell);
                    })()}
                </td>); })}
            </tr>); })}
        </tbody>
      </table>);
    })();
    return (<>
      <Box style={{
            backgroundColor: '#f1f5f9',
            padding: '0.5rem',
            borderRadius: '0.25rem',
        }}>
        <Text fontWeight='bold'>SQL Query:</Text>
        <Box paddingY='space-0.25'/>
        <pre style={{
            fontSize: '0.75rem',
            backgroundColor: '#22272e',
            color: '#e6edf3',
            padding: '0.75rem',
            borderRadius: '0.25rem',
            overflowX: 'hidden',
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            width: '100%',
        }}>
          {((_b = toolInvocation.args) === null || _b === void 0 ? void 0 : _b.query) || ''}
        </pre>
      </Box>

      {result && (<Box style={{
                backgroundColor: '#f1f5f9',
                padding: '0.5rem',
                borderRadius: '0.25rem',
                marginBottom: '0.5rem',
            }}>
          <Text fontWeight='bold'>
            Query Results (
            {result.rowCount}
            {' '}
            {result.rowCount === 1 ? 'row' : 'rows'}
            ):
          </Text>

          <Box style={{
                overflowX: 'hidden',
                margin: '0.5rem 0 0 0',
                width: '100%',
            }}>
            {resultsTable}
          </Box>

          {result.rowCount === 0 && (<Box style={{
                    padding: '1rem',
                    textAlign: 'center',
                    color: '#64748b',
                    fontStyle: 'italic',
                    backgroundColor: '#f8fafc',
                    borderRadius: '0.25rem',
                    margin: '0.5rem 0',
                }}>
              No results returned
            </Box>)}
        </Box>)}
      <Box style={{
            fontSize: '0.75rem',
            backgroundColor: '#f1f5f9',
            padding: '0.5rem',
            borderRadius: '0.25rem',
            overflowX: 'hidden',
            width: '100%',
        }}>
        <Text fontWeight='bold'>Complete Tool Details:</Text>
        <pre style={{
            fontSize: '0.75rem',
            backgroundColor: '#f8fafc',
            padding: '0.5rem',
            borderRadius: '0.25rem',
            overflowX: 'hidden',
            margin: '0.5rem 0 0 0',
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            width: '100%',
        }}>
          {JSON.stringify(__assign(__assign({}, toolInvocation), { args: toolInvocation.args
                ? __assign(__assign({}, toolInvocation.args), { query: '<see formatted SQL query above>' }) : toolInvocation.args, result: result
                ? '<see formatted table above>'
                : result }), null, 2)}
        </pre>
      </Box>
    </>);
};
var ToolPart = function (_a) {
    var part = _a.part;
    var _b = useState(true), collapsed = _b[0], setCollapsed = _b[1];
    var _c = useState(0), height = _c[0], setHeight = _c[1];
    var contentRef = useRef(null);
    var sqlContentRef = useRef(null);
    var handleToggleCollapse = function () {
        setCollapsed(function (collapsed) { return !collapsed; });
    };
    React.useEffect(function () {
        if (!collapsed) {
            if (part.toolInvocation.toolName === 'executeSqlQuery' && sqlContentRef.current) {
                setHeight(sqlContentRef.current.scrollHeight);
            }
            else if (contentRef.current) {
                setHeight(contentRef.current.scrollHeight);
            }
        }
        else {
            setHeight(0);
        }
    }, [collapsed, part.toolInvocation.toolName]);
    return (<Box background='blueFill' borderRadius='small' fontSize='small' style={{
            backgroundColor: '#f0f9ff',
        }}>
      <Flex alignItems='center' justifyContent='space-between' paddingLeft='space-0.5' paddingRight='space-0.75' 
    // flexWrap='wrap'
    style={{
            height: '2rem',
        }}>
        <Flex alignItems='center' overflow='hidden' flexGrow={1} minWidth={0} // Allows flex items to be smaller than content size
    >
          <Box as='span' role='img' aria-label='Tool' style={{
            transform: collapsed ? 'rotate(0deg)' : 'rotate(90deg)',
            transition: 'transform 0.3s ease',
            flexShrink: 0,
        }}>
            <span role='img' aria-label='Tool'>🛠️</span>
          </Box>
          <Text fontWeight='bold' style={{
            whiteSpace: 'nowrap',
        }}>
            Tool:
          </Text>
          <Text style={{
            marginLeft: '0.25rem',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        }}>
            {part.toolInvocation.toolName}
          </Text>
          <Text fontSize='small' foreground='greyDark' style={{
            marginLeft: '0.5rem',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        }}>
            ID:
            {' '}
            {part.toolInvocation.toolCallId}
          </Text>
        </Flex>
        <TextLinkButton onPress={handleToggleCollapse} flexGrow={1} textAlign='right' style={{
            position: 'relative',
            overflow: 'hidden',
            height: '100%',
        }}>
          <Box as='span' style={{
            display: 'inline-block',
            transform: collapsed ? 'translateY(0)' : 'translateY(100%)',
            transition: 'transform 0.3s ease',
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
        }}>
            Show details
          </Box>
          <Box as='span' style={{
            display: 'inline-block',
            transform: collapsed ? 'translateY(-100%)' : 'translateY(0)',
            transition: 'transform 0.3s ease',
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
        }}>
            Hide details
          </Box>
          <Box as='span' style={{ opacity: 0 }}>
            {collapsed ? 'Show details' : 'Hide details'}
          </Box>
        </TextLinkButton>
      </Flex>
      <Box style={{
            height: "".concat(height / 16, "rem"),
            overflow: 'hidden',
            transition: 'height 0.3s ease-in-out',
            marginTop: height > 0 ? '0.5rem' : 0,
        }}>
        {part.toolInvocation.toolName === 'executeSqlQuery' && (<Box ref={sqlContentRef} style={{
                opacity: collapsed ? 0 : 1,
                transition: 'opacity 0.3s ease',
            }}>
            <SqlQueryResult toolInvocation={part.toolInvocation} collapsed={collapsed}/>
          </Box>)}
        {part.toolInvocation.toolName !== 'executeSqlQuery' && (<pre ref={contentRef} style={{
                fontSize: '0.75rem',
                backgroundColor: '#f1f5f9',
                padding: '0.5rem',
                borderRadius: '0.25rem',
                overflowX: 'hidden',
                margin: 0,
                opacity: collapsed ? 0 : 1,
                transition: 'opacity 0.3s ease',
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
            }}>
            {JSON.stringify(part.toolInvocation, null, 2)}
          </pre>)}
      </Box>
    </Box>);
};
var TokenUsage = function (_a) {
    var usage = _a.usage;
    var tokens = [
        {
            label: 'Prompt tokens',
            tokenCount: usage.promptTokens,
            color: '#3b82f6', // Blue
        },
        {
            label: 'Completion tokens',
            tokenCount: usage.completionTokens,
            color: '#10b981', // Green
        },
        {
            label: 'Total tokens',
            tokenCount: usage.totalTokens,
            color: '#6366f1', // Indigo
        },
    ];
    var maxWidth = 300; // This is still in px for calculation purposes
    var maxTokens = Math.max(usage.totalTokens, 1000); // Ensure we have a minimum scale
    return (<Box background='white' borderRadius='small' border='greyLine' padding='space-1.5' fontSize='small'>
      <Text fontSize='medium' fontWeight='bold' foreground='black'>
        Token Usage
      </Text>

      <Flex flexDirection='column' gap='space-1.5'>
        {tokens.map(function (token) { return (<Flex key={token.label} alignItems='center' gap='space-1'>
            <Box style={{ width: '7.5rem', flexShrink: 0 }}>
              <Text>{token.label}</Text>
            </Box>
            <Box style={{
                width: "".concat(Math.min((token.tokenCount / maxTokens) * maxWidth, maxWidth) / 16, "rem"),
                minWidth: '2.5rem',
                height: '1.25rem',
                backgroundColor: token.color,
                borderRadius: '0.25rem',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0 0.5rem',
                transition: 'width 0.3s ease',
            }}>
              <Text fontSize='small' foreground='white'>
                {token.tokenCount.toLocaleString()}
              </Text>
            </Box>
          </Flex>); })}
      </Flex>
    </Box>);
};
var AiChatInput = function (_a) {
    var modelType = _a.modelType, modelProvider = _a.modelProvider, input = _a.input, handleInputChange = _a.handleInputChange, handleSubmit = _a.handleSubmit, status = _a.status, error = _a.error, stop = _a.stop, chatPostBody = _a.chatPostBody, inputRef = _a.inputRef;
    return (<Box borderTop='greyLine' padding='space-1.5' background='white'>
      <form onSubmit={function (event) {
            var body = __assign(__assign({ modelType: modelType, includeDebugInfo: true }, (chatPostBody !== null && chatPostBody !== void 0 ? chatPostBody : {})), { modelProvider: modelProvider === 'Claude' ? 'bedrock' : 'openai' });
            handleSubmit(event, {
                body: body,
            });
        }} style={{ width: '100%' }}>
        <Flex justifyContent='space-between' gap='space-2' alignItems='center' border='greyLine' borderRadius='small' background='white'>
          <Flex flexGrow={1}>
            <textarea ref={inputRef} name='prompt' value={input} onChange={handleInputChange} onKeyDown={function (e) {
            if (e.key === 'Enter' && !e.shiftKey && input.trim() && status === 'ready' && !error) {
                e.preventDefault();
                var body = __assign(__assign({ modelType: modelType, includeDebugInfo: true }, (chatPostBody !== null && chatPostBody !== void 0 ? chatPostBody : {})), { modelProvider: modelProvider === 'Claude' ? 'bedrock' : 'openai' });
                handleSubmit(e, { body: body });
            }
        }} disabled={status !== 'ready' || !!error} style={{
            width: '100%',
            padding: '1rem',
            border: 'none',
            outline: 'none',
            resize: 'none',
            minHeight: '1.5rem',
            maxHeight: '12.5rem',
            fontFamily: 'inherit',
            fontSize: '1rem',
            lineHeight: '1.5',
            background: 'transparent',
        }} placeholder='Message...' rows={1} autoComplete='off'/>
          </Flex>

          <Flex paddingX='space-1' paddingY='space-0.5'>
            {status === 'streaming' ? (<Button onClick={function () { return stop(); }} palette='greyDark' variant='ghost'>
                Stop
              </Button>) : (<Button type='submit' disabled={status !== 'ready' || !input.trim()} palette='orangeCTA'>
                Send
              </Button>)}
          </Flex>
        </Flex>
      </form>
    </Box>);
};
export default AiChat;

import { useChat } from '@ai-sdk/react';
import { ToolInvocationUIPart, UIMessage } from '@ai-sdk/ui-utils';
import { ChatRequestOptions, ToolInvocation } from 'ai';
import { isLeft } from 'fp-ts/lib/Either';
import * as t from 'io-ts';
import React, { useRef, useState } from 'react';
import urlJoin from 'url-join';

import { ensureMableError, ExcludeUnexpected, MableError } from '@mablemarket/common-lib';
import { AiChatModelType, AiChatPostBody } from '@mablemarket/core-api-client';

import Box from '../Box';
import Button from '../Button';
import Flex from '../Flex';
import Loading from '../Loading';
import Markdown from '../Markdown';
import Select from '../Select';
import Text from '../Text';
import TextLinkButton from '../TextLinkButton';

export interface AiChatProps {
  accessToken: string;
  impersonationID?: string;
  baseApiUrl: string;
  /** e.g. '/v1/chat' */
  aiChatEndpoint: string;
  /** Additional configurations sent to the chat end point */
  showDeveloperFeatures: boolean;
  chatPostBody?: Partial<AiChatPostBody>;
}

type ModelProvider = 'OpenAI' | 'Claude';

const exampleQueries = [
  'Break down my order volume by distributor',
  'Show me my top products ordered by volume',
  'When did I last order [product]?',
];

const AiChat = ({
  accessToken,
  impersonationID,
  chatPostBody,
  baseApiUrl,
  showDeveloperFeatures,
  aiChatEndpoint,
}: AiChatProps) => {
  const [modelType, setModelType] = useState<ExcludeUnexpected<AiChatModelType>>('textGenerationSmart');
  const [modelProvider, setModelProvider] = useState<ModelProvider>('Claude');
  const [lastError, setLastError] = useState<MableError | undefined>(undefined);
  const [tokenUsage, setTokenUsage] = useState<TokenUsage | undefined>(undefined);
  const [showTokenUsage, setShowTokenUsage] = useState(false);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const handleDelete = (id: string) => {
    setMessages(messages.filter(message => message.id !== id));
  };

  const additionalHeaders = {
    ...(impersonationID ? { 'x-mable-impersonate-user-id': impersonationID } : {}),
  };
  // useChat reference: https://sdk.vercel.ai/docs/reference/ai-sdk-ui/use-chat
  const {
    messages,
    setMessages,
    input,
    handleInputChange,
    setInput,
    handleSubmit,
    status,
    stop,
    error,
    reload,
  } =
    useChat({
      api: urlJoin(baseApiUrl, aiChatEndpoint),
      headers: {
        Authorization: `Bearer ${accessToken}`,
        ...additionalHeaders,
      },
      body: {
        includeDebugInfo: true,
      },
      onFinish: (message, { usage, finishReason }) => {
        setTokenUsage(usage);
      },
      onError: (error) => {
        setLastError(ensureMableError(error));
      },
      onResponse: (response) => {
        // ...
      },
    });

  const modelTypeLabel: Record<ExcludeUnexpected<AiChatModelType>, string> = {
    reasoningFaster: 'Reasoning, Fast',
    reasoningSmarter: 'Reasoning, Smart',
    textGenerationMini: 'Standard, Fast',
    textGenerationSmart: 'Standard, Smart',
    textGenerationSmarter: 'Standard, Smarter (4.5 preview)',
  };

  const modelSelectionHeader = (
    <Box
      paddingX='space-1'
      paddingY='space-1'
      borderBottom='greyLine'
      background='greyFill'
    >
      <Flex>
        <Flex>
          <Select
            onChange={e => setModelProvider(e.target.value as ModelProvider)}
            value={modelProvider}
          >
            <option value='OpenAI'>OpenAI</option>
            <option value='Claude'>Claude</option>
          </Select>
        </Flex>
        <Flex>
          <Select
            onChange={e => setModelType(e.target.value as ExcludeUnexpected<AiChatModelType>)}
            value={modelType}
          >
            {AiChatModelType.values.map(type => (
              <option key={type} value={type}>
                {modelTypeLabel[type]}
              </option>
            ))}
          </Select>
        </Flex>
        {tokenUsage && showDeveloperFeatures && (
          <Button
            onClick={() => setShowTokenUsage(!showTokenUsage)}
            palette='greyDark'
            variant='ghost'
            size='small'
          >
            {showTokenUsage ? 'Hide Tokens' : 'Show Tokens'}
          </Button>
        )}
      </Flex>
    </Box>
  );

  return (
    <Flex
      flexDirection='column'
      background='white'
      borderRadius='medium'
      border='greyLine'
      style={{
        overflow: 'hidden',
      }}
    >

      {showDeveloperFeatures && modelSelectionHeader}

      {/* Messages container */}
      <Flex
        paddingTop='space-1'
        paddingX='space-2'
        gap='space-0.5'
        flexGrow={1}
        flexDirection='column'
        background='white'
        style={{
          overflowY: 'auto',
          width: '100%',
        }}
      >
        {!showDeveloperFeatures && messages.length === 0 && (
          <Flex flexDirection='column' alignItems='center' justifyContent='center' flexGrow={1} paddingY='space-4'>
            <Text fontSize='h3' fontWeight='bold' paddingBottom='space-1.5'>
              What can I help with?
            </Text>
            <Text fontSize='medium' foreground='greyDark' textAlign='center' paddingBottom='space-2'>
              You can ask me anything about your order data. For example:
            </Text>
            <Flex flexDirection='column' gap='space-1' style={{ width: '80%', maxWidth: '30rem' }}>
              {exampleQueries.map(example => (
                <Box
                  key={example}
                  background='greyFill'
                  padding='space-1.5'
                  borderRadius='medium'
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setInput(example);
                    setTimeout(() => inputRef.current?.focus(), 0);
                  }}
                >
                  <Text>{example}</Text>
                </Box>
              ))}
            </Flex>
          </Flex>
        )}

        {messages.length > 0 && (
          messages.map((message, idx) => {
            return ([
              (idx > 0 && message.role === 'user') ? <Box paddingY='space-1' /> : null,
              <ChatMessage
                key={`${message.id}`}
                message={message}
                handleDelete={handleDelete}
              />,
              (idx < messages.length - 1 && message.role === 'user') ? <Box paddingY='space-0.5' /> : null,
            ]);
          })
        )}

        {status === 'submitted' && (
          <Flex paddingY='space-2' justifyContent='center'>
            <Loading size={30} />
          </Flex>
        )}

        {error && (
          <Box
            style={{
              padding: '0.75rem',
              backgroundColor: '#fef2f2',
              border: '0.0625rem solid #fee2e2',
              color: '#b91c1c',
            }}
            borderRadius='small'
          >
            <Box>
              <Text fontWeight='bold'>{lastError?.displayTitle}</Text>
              :
              {lastError?.displayMessage}
            </Box>
            <Button
              onClick={() => reload()}
              palette='greyDark'
              size='small'
            >
              Retry
            </Button>
          </Box>
        )}

        {/* Token usage stats */}
        {showTokenUsage && tokenUsage && (
          <Box>
            <TokenUsage usage={tokenUsage} />
          </Box>
        )}

        <Box paddingY='space-1' />
      </Flex>

      <AiChatInput
        modelType={modelType}
        modelProvider={modelProvider}
        input={input}
        handleInputChange={handleInputChange}
        handleSubmit={handleSubmit}
        status={status}
        error={error}
        stop={stop}
        chatPostBody={chatPostBody}
        inputRef={inputRef}
      />
    </Flex>
  );
};

const ChatMessage = ({ message, handleDelete }: {
  message: UIMessage,
  handleDelete: (id: string) => void,
}) => {
  const isUser = message.role === 'user';

  return (
    <Box background='white' style={{ width: '100%' }}>
      <Box style={{ maxWidth: '100%', overflowWrap: 'break-word' }}>
        <Flex
          alignItems='flex-start'
          justifyContent={isUser ? 'flex-end' : 'flex-start'}
          style={{ width: '100%' }}
        >
          {/* Message content */}
          <Box
            flexGrow={0}
            textAlign={isUser ? 'right' : 'left'}
            style={{
              width: isUser ? 'auto' : '100%',
              maxWidth: isUser ? '80%' : '100%',
            }}
          >
            {message.parts.map((part, index) => {
              if (part.type === 'text') {
                return (
                  <Box
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    fontSize='medium'
                    background={isUser ? 'greyFill' : undefined}
                    borderRadius={isUser ? 'medium' : undefined}
                    padding={isUser ? 'space-1' : undefined}
                  >
                    <Box>
                      <Markdown source={part.text} />
                    </Box>
                  </Box>
                );
              }
              if (part.type === 'reasoning') {
                return (
                  <Box
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    borderRadius='small'
                    fontSize='small'
                    style={{
                      backgroundColor: '#f0fdfa',
                      padding: '0.5rem 0.75rem',
                    }}
                  >
                    <Box>
                      <Text fontWeight='bold'>Reasoning:</Text>
                      {' '}
                      {part.reasoning}
                    </Box>
                    <details>
                      <summary style={{ cursor: 'pointer' }}>Details</summary>
                      <pre
                        style={{
                          fontSize: '0.75rem',
                          backgroundColor: '#f1f5f9',
                          padding: '0.5rem',
                          borderRadius: '0.25rem',
                          overflowX: 'hidden',
                        }}
                      >
                        {JSON.stringify(part.details, null, 2)}
                      </pre>
                    </details>
                  </Box>
                );
              }
              if (part.type === 'source') {
                return (
                  <Box
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    borderRadius='small'
                    fontSize='small'
                    style={{
                      backgroundColor: '#eff6ff',
                      padding: '0.5rem 0.75rem',
                    }}
                  >
                    <Text fontWeight='bold'>Source:</Text>
                    <pre
                      style={{
                        fontSize: '0.75rem',
                        backgroundColor: '#f1f5f9',
                        padding: '0.5rem',
                        borderRadius: '0.25rem',
                        overflowX: 'hidden',
                      }}
                    >
                      {JSON.stringify(part.source, null, 2)}
                    </pre>
                  </Box>
                );
              }
              if (part.type === 'tool-invocation') {
                return ([
                  <Box key={`${part.toolInvocation.toolCallId}-spacer`} paddingY='space-0.25' />,
                  <ToolPart
                    key={`${part.toolInvocation.toolCallId}`}
                    part={part}
                  />,
                ]);
              }
              if (part.type === 'step-start') {
                // eslint-disable-next-line react/no-array-index-key
                return index > 0 ? (<br key={index} />) : null;
              }
              return (
                // eslint-disable-next-line react/no-array-index-key
                <Text key={index} foreground='greyDark' style={{ fontStyle: 'italic' }}>
                  Unhandled message part type:
                  {' '}
                  {part.type}
                </Text>
              );
            })}
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};


interface SqlQueryResultProps {
  toolInvocation: ToolInvocation;
  collapsed: boolean;
}

const SqlQueryResultType = t.type({
  rows: t.array(t.record(t.string, t.union([t.string, t.number, t.boolean, t.null]))),
  rowCount: t.number,
});

const SqlQueryResult = ({
  toolInvocation,
  collapsed,
}: SqlQueryResultProps) => {
  const result = (() => {
    if (toolInvocation.state !== 'result' || !toolInvocation.result) {
      return null;
    }
    const decodedResult = SqlQueryResultType.decode(toolInvocation.result);
    if (isLeft(decodedResult)) {
      return null;
    }
    return decodedResult.right;
  })();

  const resultsTable = (() => {
    if (!result || !result.rows || !result.rows[0]) {
      return null;
    }

    const tableStyles = {
      table: {
        width: '100%',
        tableLayout: 'fixed' as const,
        borderCollapse: 'collapse' as const,
        fontSize: '0.75rem',
      },
      headerRow: {
        backgroundColor: '#e2e8f0',
      },
      headerCell: {
        padding: '0.5rem',
        textAlign: 'left' as const,
        borderBottom: '0.125rem solid #cbd5e1',
        fontWeight: 'bold' as const,
      },
      row: (isEven: boolean) => ({
        backgroundColor: isEven ? '#f8fafc' : '#ffffff',
      }),
      cell: {
        padding: '0.5rem',
        borderBottom: '0.0625rem solid #e2e8f0',
        wordBreak: 'break-word' as const,
        overflow: 'hidden' as const,
        textOverflow: 'ellipsis' as const,
      },
      nullValue: {
        color: '#94a3b8',
      },
    };

    return (
      <table style={tableStyles.table}>
        <thead>
          <tr style={tableStyles.headerRow}>
            {Object.keys(result.rows[0]).map((column, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <th key={index} style={tableStyles.headerCell}>
                {column}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {result.rows.map((row, rowIndex) => (
            // eslint-disable-next-line react/no-array-index-key
            <tr key={rowIndex} style={tableStyles.row(rowIndex % 2 === 0)}>
              {Object.values(row).map((cell, cellIndex) => (
                // eslint-disable-next-line react/no-array-index-key
                <td key={cellIndex} style={tableStyles.cell}>
                  {(() => {
                    if (cell === null) return <em style={tableStyles.nullValue}>NULL</em>;
                    if (typeof cell === 'object') return JSON.stringify(cell);
                    return String(cell);
                  })()}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  })();

  return (
    <>
      <Box
        style={{
          backgroundColor: '#f1f5f9',
          padding: '0.5rem',
          borderRadius: '0.25rem',
        }}
      >
        <Text fontWeight='bold'>SQL Query:</Text>
        <Box paddingY='space-0.25' />
        <pre
          style={{
            fontSize: '0.75rem',
            backgroundColor: '#22272e',
            color: '#e6edf3',
            padding: '0.75rem',
            borderRadius: '0.25rem',
            overflowX: 'hidden',
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            width: '100%',
          }}
        >
          {toolInvocation.args?.query || ''}
        </pre>
      </Box>

      {result && (
        <Box
          style={{
            backgroundColor: '#f1f5f9',
            padding: '0.5rem',
            borderRadius: '0.25rem',
            marginBottom: '0.5rem',
          }}
        >
          <Text fontWeight='bold'>
            Query Results (
            {result.rowCount}
            {' '}
            {result.rowCount === 1 ? 'row' : 'rows'}
            ):
          </Text>

          <Box
            style={{
              overflowX: 'hidden',
              margin: '0.5rem 0 0 0',
              width: '100%',
            }}
          >
            {resultsTable}
          </Box>

          {result.rowCount === 0 && (
            <Box
              style={{
                padding: '1rem',
                textAlign: 'center',
                color: '#64748b',
                fontStyle: 'italic',
                backgroundColor: '#f8fafc',
                borderRadius: '0.25rem',
                margin: '0.5rem 0',
              }}
            >
              No results returned
            </Box>
          )}
        </Box>
      )}
      <Box
        style={{
          fontSize: '0.75rem',
          backgroundColor: '#f1f5f9',
          padding: '0.5rem',
          borderRadius: '0.25rem',
          overflowX: 'hidden',
          width: '100%',
        }}
      >
        <Text fontWeight='bold'>Complete Tool Details:</Text>
        <pre
          style={{
            fontSize: '0.75rem',
            backgroundColor: '#f8fafc',
            padding: '0.5rem',
            borderRadius: '0.25rem',
            overflowX: 'hidden',
            margin: '0.5rem 0 0 0',
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            width: '100%',
          }}
        >
          {JSON.stringify(
            {
              ...toolInvocation,
              args: toolInvocation.args
                ? { ...toolInvocation.args, query: '<see formatted SQL query above>' }
                : toolInvocation.args,
              result: result
                ? '<see formatted table above>'
                : result,
            },
            null,
            2,
          )}
        </pre>
      </Box>
    </>
  );
};

const ToolPart = ({
  part,
}: {
  part: ToolInvocationUIPart,
}) => {
  const [collapsed, setCollapsed] = useState(true);
  const [height, setHeight] = useState(0);
  const contentRef = useRef<HTMLPreElement>(null);
  const sqlContentRef = useRef<HTMLDivElement>(null);

  const handleToggleCollapse = () => {
    setCollapsed(collapsed => !collapsed);
  };

  React.useEffect(() => {
    if (!collapsed) {
      if (part.toolInvocation.toolName === 'executeSqlQuery' && sqlContentRef.current) {
        setHeight(sqlContentRef.current.scrollHeight);
      } else if (contentRef.current) {
        setHeight(contentRef.current.scrollHeight);
      }
    } else {
      setHeight(0);
    }
  }, [collapsed, part.toolInvocation.toolName]);

  return (
    <Box
      background='blueFill'
      borderRadius='small'
      fontSize='small'
      style={{
        backgroundColor: '#f0f9ff',
      }}
    >
      <Flex
        alignItems='center'
        justifyContent='space-between'
        paddingLeft='space-0.5'
        paddingRight='space-0.75'
        // flexWrap='wrap'
        style={{
          height: '2rem',
        }}
      >
        <Flex
          alignItems='center'
          overflow='hidden'
          flexGrow={1}
          minWidth={0} // Allows flex items to be smaller than content size
        >
          <Box
            as='span'
            role='img'
            aria-label='Tool'
            style={{
              transform: collapsed ? 'rotate(0deg)' : 'rotate(90deg)',
              transition: 'transform 0.3s ease',
              flexShrink: 0,
            }}
          >
            <span role='img' aria-label='Tool'>🛠️</span>
          </Box>
          <Text
            fontWeight='bold'
            style={{
              whiteSpace: 'nowrap',
            }}
          >
            Tool:
          </Text>
          <Text
            style={{
              marginLeft: '0.25rem',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {part.toolInvocation.toolName}
          </Text>
          <Text
            fontSize='small'
            foreground='greyDark'
            style={{
              marginLeft: '0.5rem',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            ID:
            {' '}
            {part.toolInvocation.toolCallId}
          </Text>
        </Flex>
        <TextLinkButton
          onPress={handleToggleCollapse}
          flexGrow={1}
          textAlign='right'
          style={{
            position: 'relative',
            overflow: 'hidden',
            height: '100%',
          }}
        >
          <Box
            as='span'
            style={{
              display: 'inline-block',
              transform: collapsed ? 'translateY(0)' : 'translateY(100%)',
              transition: 'transform 0.3s ease',
              position: 'absolute',
              width: '100%',
              height: '100%',
              left: 0,
            }}
          >
            Show details
          </Box>
          <Box
            as='span'
            style={{
              display: 'inline-block',
              transform: collapsed ? 'translateY(-100%)' : 'translateY(0)',
              transition: 'transform 0.3s ease',
              position: 'absolute',
              width: '100%',
              height: '100%',
              left: 0,
            }}
          >
            Hide details
          </Box>
          <Box as='span' style={{ opacity: 0 }}>
            {collapsed ? 'Show details' : 'Hide details'}
          </Box>
        </TextLinkButton>
      </Flex>
      <Box
        style={{
          height: `${height / 16}rem`,
          overflow: 'hidden',
          transition: 'height 0.3s ease-in-out',
          marginTop: height > 0 ? '0.5rem' : 0,
        }}
      >
        {part.toolInvocation.toolName === 'executeSqlQuery' && (
          <Box
            ref={sqlContentRef}
            style={{
              opacity: collapsed ? 0 : 1,
              transition: 'opacity 0.3s ease',
            }}
          >
            <SqlQueryResult toolInvocation={part.toolInvocation} collapsed={collapsed} />
          </Box>
        )}
        {part.toolInvocation.toolName !== 'executeSqlQuery' && (
          <pre
            ref={contentRef}
            style={{
              fontSize: '0.75rem',
              backgroundColor: '#f1f5f9',
              padding: '0.5rem',
              borderRadius: '0.25rem',
              overflowX: 'hidden',
              margin: 0,
              opacity: collapsed ? 0 : 1,
              transition: 'opacity 0.3s ease',
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
            }}
          >
            {JSON.stringify(part.toolInvocation, null, 2)}
          </pre>
        )}
      </Box>
    </Box>
  );
};

interface TokenUsage {
  promptTokens: number;
  completionTokens: number;
  totalTokens: number;
}

const TokenUsage = ({ usage }: {
  usage: TokenUsage,
}) => {
  const tokens = [
    {
      label: 'Prompt tokens',
      tokenCount: usage.promptTokens,
      color: '#3b82f6', // Blue
    },
    {
      label: 'Completion tokens',
      tokenCount: usage.completionTokens,
      color: '#10b981', // Green
    },
    {
      label: 'Total tokens',
      tokenCount: usage.totalTokens,
      color: '#6366f1', // Indigo
    },
  ];

  const maxWidth = 300; // This is still in px for calculation purposes
  const maxTokens = Math.max(usage.totalTokens, 1000); // Ensure we have a minimum scale

  return (
    <Box
      background='white'
      borderRadius='small'
      border='greyLine'
      padding='space-1.5'
      fontSize='small'
    >
      <Text
        fontSize='medium'
        fontWeight='bold'
        foreground='black'
      >
        Token Usage
      </Text>

      <Flex flexDirection='column' gap='space-1.5'>
        {tokens.map(token => (
          <Flex key={token.label} alignItems='center' gap='space-1'>
            <Box style={{ width: '7.5rem', flexShrink: 0 }}>
              <Text>{token.label}</Text>
            </Box>
            <Box
              style={{
                width: `${Math.min((token.tokenCount / maxTokens) * maxWidth, maxWidth) / 16}rem`,
                minWidth: '2.5rem',
                height: '1.25rem',
                backgroundColor: token.color,
                borderRadius: '0.25rem',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0 0.5rem',
                transition: 'width 0.3s ease',
              }}
            >
              <Text fontSize='small' foreground='white'>
                {token.tokenCount.toLocaleString()}
              </Text>
            </Box>
          </Flex>
        ))}
      </Flex>
    </Box>
  );
};

interface AiChatInputProps {
  modelType: ExcludeUnexpected<AiChatModelType>;
  modelProvider: ModelProvider;
  input: string;
  handleInputChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleSubmit: (event: { preventDefault?: () => void }, options?: ChatRequestOptions) => void;
  status: 'awaiting-input' | 'loading' | 'submitted' | 'streaming' | 'ready' | 'error';
  error?: Error;
  stop: () => void;
  chatPostBody?: Partial<AiChatPostBody>;
  inputRef: React.RefObject<HTMLTextAreaElement>;
}

const AiChatInput = ({
  modelType,
  modelProvider,
  input,
  handleInputChange,
  handleSubmit,
  status,
  error,
  stop,
  chatPostBody,
  inputRef,
}: AiChatInputProps) => {
  return (
    <Box
      borderTop='greyLine'
      padding='space-1.5'
      background='white'
    >
      <form
        onSubmit={(event) => {
          const body: AiChatPostBody = {
            modelType,
            includeDebugInfo: true,
            ...(chatPostBody ?? {}),
            modelProvider: modelProvider === 'Claude' ? 'bedrock' : 'openai',
          };
          handleSubmit(event, {
            body,
          });
        }}
        style={{ width: '100%' }}
      >
        <Flex
          justifyContent='space-between'
          gap='space-2'
          alignItems='center'
          border='greyLine'
          borderRadius='small'
          background='white'
        >
          <Flex flexGrow={1}>
            <textarea
              ref={inputRef}
              name='prompt'
              value={input}
              onChange={handleInputChange}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey && input.trim() && status === 'ready' && !error) {
                  e.preventDefault();
                  const body: AiChatPostBody = {
                    modelType,
                    includeDebugInfo: true,
                    ...(chatPostBody ?? {}),
                    modelProvider: modelProvider === 'Claude' ? 'bedrock' : 'openai',
                  };
                  handleSubmit(e, { body });
                }
              }}
              disabled={status !== 'ready' || !!error}
              style={{
                width: '100%',
                padding: '1rem',
                border: 'none',
                outline: 'none',
                resize: 'none',
                minHeight: '1.5rem',
                maxHeight: '12.5rem',
                fontFamily: 'inherit',
                fontSize: '1rem',
                lineHeight: '1.5',
                background: 'transparent',
              }}
              placeholder='Message...'
              rows={1}
              autoComplete='off'
            />
          </Flex>

          <Flex paddingX='space-1' paddingY='space-0.5'>
            {status === 'streaming' ? (
              <Button
                onClick={() => stop()}
                palette='greyDark'
                variant='ghost'
              >
                Stop
              </Button>
            ) : (
              <Button
                type='submit'
                disabled={status !== 'ready' || !input.trim()}
                palette='orangeCTA'
              >
                Send
              </Button>
            )}
          </Flex>
        </Flex>
      </form>
    </Box>
  );
};

export default AiChat;
